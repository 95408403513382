import React, { useEffect, useState, PureComponent } from "react"
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer, BarChart, Bar, Rectangle, ComposedChart } from 'recharts'
import './App.css';

function App() {

  const [matches, setMatches] = useState([])
  const [apiMatches, setApiMatches] = useState([])
  const [loading, setLoading] = useState(true)
  const [types, setTypes] = useState([])
  const [filterTime, setFilterTime] = useState('all')
  const [filterPercent, setFilterPercent] = useState('all')
  const [typeOn, setTypeOn] = useState('all')
  const [viewType, setViewType] = useState('all')
  const viewTypes = ['all', 'dropps only']
  const filtersTime = ['all', '1h', '4h', '8h', '16h', '24h']
  const filtersPercent = ['all', '1', '5', '10']
  const [click, setClick] = useState(-1)
  const [updateTime, setUpdateTime] = useState(new Date())
  const hms = 3600000


  function getDiffTimes(time1, time2) {

    let date1 = new Date(time1)
    let date2 = new Date(time2)

    if (date2 < date1) {
      date2.setDate(date2.getDate() + 1)
    }

    let diff = date2 - date1
    let ms = diff % 1000;
    let ss = Math.floor(diff / 1000) % 60
    let mm = Math.floor(diff / 1000 / 60) % 60
    let hh = Math.floor(diff / 1000 / 60 / 60)

    return `${hh}hr, ${mm}min`

  }

  function getDiffTimeMs(time1, time2) {
    let date1 = new Date(time1)
    let date2 = new Date(time2)

    if (date2 < date1) {
      date2.setDate(date2.getDate() + 1)
    }

    let diff = date2 - date1
    return diff
  }

  const getApiData = async () => {
    const response = await fetch(
      'https://0xodds.com/getUpcomingMatches'
      //"http://localhost:3001/getUpcomingMatches"
    ).then((response) => response.json());

    let types = ['all']
    for (let i = 0; i < response.length; i++) {
      if (types.indexOf(response[i].type) == -1) {
        types.push(response[i].type)
      }
    }


    setMatches(response)
    setApiMatches(response)
    setLoading(false)
    setTypes(types)
    setUpdateTime(new Date())
  };

  useEffect((e) => {
    console.log(e)
    getApiData();
    setInterval(() => getApiData(), 60000);
  }, []);


  useEffect(() => {
    let newMatches = apiMatches.filter((match) => typeOn == 'all' ? match : match.type == typeOn)
    let time = filterTime == 'all' ? 0 : Number(filterTime.replace('h', '')) * hms
    newMatches = newMatches.filter((match) => filterTime == 'all' ? match : time == 0 ? match : getDiffTimeMs(new Date(), match.startTime) <= time && getDiffTimeMs(new Date(), match.startTime) > 0 ? match : false)
    newMatches = newMatches.sort((a, b) => a.startTime - b.startTime)
    setMatches(newMatches)
  }, [apiMatches, typeOn, filterTime, viewType, filterPercent])

  function renderGraph(renderData) {

    let data = []
    for (let i = 0; i < renderData.length; i++) {
      data.push({
        name: renderData[i].inserTime,
        odd: renderData[i].odd
      })
    }

    return (
      <LineChart
        width={500}
        height={150}
        data={data}
        margin={{
          top: 5,
          right: 30,
          left: 20,
          bottom: 5,
        }}
      >
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis dataKey="name" />
        <YAxis />
        <Tooltip />
        <Legend />
        <Line type="monotone" dataKey="odd" stroke="#8884d8" activeDot={{ r: 8 }} />
      </LineChart>
    );
  }

  function renderGraphFund(renderData) {

    let data = []
    for (let i = 0; i < renderData.length; i++) {
      data.push({
        name: renderData[i].inserTime,
        fund: renderData[i].fund
      })
    }

    return (
      <BarChart
        width={500}
        height={150}
        data={data}
        margin={{
          top: 5,
          right: 30,
          left: 20,
          bottom: 5,
        }}
      >
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis dataKey="name" />
        <YAxis />
        <Tooltip />
        <Legend />
        <Bar dataKey="fund" fill="#82ca9d" />
      </BarChart>
    );
  }

  function renderDiffOdd(data) {
    //matches[click].odds[odd].azuro
    let a = Number(data[0].odd)
    let b = Number(data[data.length - 1].odd)
    let c = (data.length > 1) ? Number(data[data.length - 2].odd) : Number(data[data.length - 1].odd)

    let diffFirstAndLast = (data.length == 0) ? 0 : ((b - a) / ((a + b) / 2)) * 100
    let diffLatests = (data.length == 0) ? 0 : ((b - c) / ((b + c) / 2)) * 100

    diffFirstAndLast = (Math.round(diffFirstAndLast * 100) / 100).toFixed(2)
    diffLatests = (Math.round(diffLatests * 100) / 100).toFixed(2)

    return (
      <div>
        <p>First and last: <span className={diffFirstAndLast >= 0 ? 'positive-number' : 'negative-number'}>{diffFirstAndLast}%</span></p>
        <p>Latests odd: <span className={diffLatests >= 0 ? 'positive-number' : 'negative-number'}>{diffLatests}%</span></p>
      </div>
    )
  }

  function renderDiffTime(data) {
    let a = data[0].inserTime
    let b = data[data.length - 1].inserTime
    let c = (data.length > 1) ? data[data.length - 2].inserTime : data[data.length - 1].inserTime

    let diffFirstAndLast = (data.length == 0) ? 0 : getDiffTimes(a, b)
    let diffLatests = (data.length == 0) ? 0 : getDiffTimes(b, new Date())

    return (
      <div>
        <p>First and last: <b>{diffFirstAndLast}</b></p>
        <p>Latests odd: <b>{diffLatests}</b></p>
      </div>
    )
  }

  function getDiffTime(data) {
    let a = data[0].inserTime
    let b = data[data.length - 1].inserTime
    let c = (data.length > 1) ? data[data.length - 2].inserTime : data[data.length - 1].inserTime

    let diffFirstAndLast = (data.length == 0) ? 0 : getDiffTimeMs(a, b)
    let diffLatests = (data.length == 0) ? 0 : getDiffTimeMs(b, new Date())

    return {
      diffFirstAndLast, diffLatests
    }
  }

  function getDiffOdd(data) {
    let a = Number(data[0].odd)
    let b = Number(data[data.length - 1].odd)
    let c = (data.length > 1) ? Number(data[data.length - 2].odd) : Number(data[data.length - 1].odd)

    let diffFirstAndLast = (data.length == 0) ? 0 : ((b - a) / ((a + b) / 2)) * 100
    let diffLatests = (data.length == 0) ? 0 : ((b - c) / ((b + c) / 2)) * 100

    diffFirstAndLast = (Math.round(diffFirstAndLast * 100) / 100).toFixed(2)
    diffLatests = (Math.round(diffLatests * 100) / 100).toFixed(2)

    return {
      diffFirstAndLast,
      diffLatests
    }
  }

  function renderDroppsOdds() {

    let newMatches = typeOn == 'all' ? matches : matches
    let droppsArray = []

    /*
      match : { }
      odd : 1.38
      oddType : 'Match full',
      droppsTime : ''
    */

    for (let i = 0; i < newMatches.length; i++) {
      let match = newMatches[i]

      for (let j in match.odds) {

        let oddType = j
        let oddsDiff = getDiffOdd(match.odds[j].azuro)
        let timesDiff = getDiffTime(match.odds[j].azuro)

        let obj = {
          matchName: match.matchName,
          startTime: match.startTime,
          type: match.type,
          oddType: '',
          dropsTime: 0,
          percentDrops: 0,
          odd: 0
        }

        obj['oddType'] = oddType
        obj['dropsTime'] = timesDiff.diffLatests
        obj['percentDrops'] = Number(oddsDiff.diffLatests)
        obj['odd'] = match.odds[j].azuro[match.odds[j].azuro.length - 1].odd

        let percent = (filterPercent == 'all') ? -0.5 : Number(filterPercent) * -1

        if (obj.percentDrops < percent) {
          droppsArray.push(obj)
        }

        droppsArray = droppsArray.sort((a, b) => a.dropsTime - b.dropsTime)

      }
    }

    function renderTime(diff) {

      let ms = diff % 1000;
      let ss = Math.floor(diff / 1000) % 60
      let mm = Math.floor(diff / 1000 / 60) % 60
      let hh = Math.floor(diff / 1000 / 60 / 60)

      return `${hh}hr, ${mm}min ${ss}sec ${ms}msec`
    }

    return (
      <table>
        <tr>
          <th>Name</th>
          <th>Start Time</th>
          <th>Sport Type</th>
          <th>Odd Type</th>
          <th>Percent Dropp</th>
          <th>Current Odd</th>
          <th>Dropp Time</th>
        </tr>
        {
          droppsArray.map((match, index) => (
            <tr>
              <td>{match.matchName}</td>
              <td>{new Date(match.startTime).toLocaleString()}</td>
              <td>{match.type}</td>
              <td>{match.oddType}</td>
              <td>{match.percentDrops}</td>
              <td>{match.odd}</td>
              <td>{renderTime(match.dropsTime)}</td>
            </tr>
          ))
        }
      </table>
    )
  }



  return (
    <div className="App">
      <header className="App-header">
        <img className="App-header-logo" src="./logo.png" />
        <h1>Oxodds</h1>
      </header>
      {
        loading ? <b>Loading...</b> : ''
      }
      <br></br>
      <p>Last time update - {updateTime.toLocaleString()}</p>
      <div className="App-filters">
        <label className="App-filters-item">Sport Type
          <select value={typeOn} onChange={(e) => setTypeOn(e.target.value)}>
            {
              types.map((type, index) => (
                <option value={type}>{type}</option>
              ))
            }
          </select>
        </label>
        <br></br>
        <label className="App-filters-item">View Type
          <select value={viewType} onChange={(e) => setViewType(e.target.value)}>
            {
              viewTypes.map((type, index) => (
                <option value={type}>{type}</option>
              ))
            }
          </select>
        </label>
        <br></br>
        <label className="App-filters-item">Filter start time
          <select value={filterTime} onChange={(e) => setFilterTime(e.target.value)}>
            {
              filtersTime.map((type, index) => (
                <option value={type}>{type}</option>
              ))
            }
          </select>
        </label>
        <br></br>
        {
          viewType == 'dropps only' &&
          <label className="App-filters-item">Filter percent dropp
            <select value={filterPercent} onChange={(e) => setFilterPercent(e.target.value)}>
              {
                filtersPercent.map((type, index) => (
                  <option value={type}>{type}</option>
                ))
              }
            </select>
          </label>
        }
      </div>
      {
        viewType == 'all' ?
          <div>
            <table>
              <tr>
                <th>Match Name</th>
                <th>Start time</th>
                <th>Type</th>
              </tr>
              {
                matches.map((match, index) => (
                  <tr onClick={() => setClick(index)}>
                    <td>{match.matchName}</td>
                    <td>{new Date(match.startTime).toLocaleString()}</td>
                    <td>{match.type}</td>
                  </tr>
                ))
              }
            </table>
            {
              click !== -1 &&
              <div className='modal-view'>
                <i className='modal-view-close-button' onClick={() => setClick(-1)}>(x) close</i>
                <p>{matches[click].matchName} / {new Date(matches[click].startTime).toLocaleString()} / {matches[click].type}</p>
                <table>
                  <tr>
                    <th>Market Name</th>
                    <th>Diff odds</th>
                    <th>Diff time</th>
                    <th>Graph Odd</th>
                    <th>Graph Fund (USDT)</th>
                  </tr>
                  {
                    Object.keys(matches[click].odds).map((odd) => (
                      <tr>
                        <td>{odd}</td>
                        <td>
                          {renderDiffOdd(matches[click].odds[odd].azuro)}
                        </td>
                        <td>
                          {renderDiffTime(matches[click].odds[odd].azuro)}
                        </td>
                        <td>
                          {renderGraph(matches[click].odds[odd].azuro)}
                          {/*matches[click].odds[odd].azuro.map(o => (
                      <p>{o.inserTime} - {o.odd}</p>
                    ))*/}
                        </td>
                        <td>
                          {renderGraphFund(matches[click].odds[odd].azuro)}
                        </td>
                      </tr>
                    ))
                  }
                </table>
              </div>
            }
          </div>
          :
          <div>
            {renderDroppsOdds()}
          </div>
      }
    </div>
  );
}

export default App;
